import React, {useEffect, useState, useCallback} from 'react';
import {
    useDataProvider,
    TopToolbar,
    CreateButton,
    Create,
    SimpleForm,
    TextInput,
    FormDataConsumer,
    Toolbar,
    useSaveContext,
    useRedirect,
    useNotify,
    Edit,
    EditButton,
    ShowButton,
    SimpleShowLayout,
    Show,
    TextField,
    useRecordContext,
    Button,
    Title,
    useRefresh,
    Confirm,
    required
} from 'react-admin';
import { useDispatch, useSelector} from 'react-redux';
import { setDealershipGroupDetails, setDealershipGroupTotalItems } from '../actions/dealershipGroupActions';
import {BRAND_DETAILS} from './Brands.js';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {useParams} from 'react-router-dom';
import Loader from '../components/Loader';
import NotFound from '../components/Profile/NotFound/NotFound';
import DeleteIcon from '../assets/icons/DeleteIcon';
import { setPage } from '../actions/pageActions';
import Pagination from '../components/Pagination';
import Chip from '@mui/material/Chip';
import { debounce } from 'lodash';

const useFetchDealershipGroups = () => {
    const page = useSelector(state => state.page.page || 1);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const dealershipGroups = useSelector(state => state.dealershipGroup.details || []);
    const [loading, setLoading] = useState(!dealershipGroups || dealershipGroups.length === 0);

    const fetchDealershipGroups = async () => {
        setLoading(true);
        try{
            const dealershipGroupParams ={
                pagination: { page: page }
            };
            const {data, total} = await dataProvider.getList('dealershipGroups', dealershipGroupParams);
            dispatch(setDealershipGroupTotalItems(total));
            dispatch(setDealershipGroupDetails(data));
            setLoading(false);
        }catch(error){
            console.error('Error fetching dealerships groups:', error);
            setLoading(false);
        }
        finally{
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchDealershipGroups();
    },[page, selectedBrand, dataProvider])

    return loading;
}

const DealershipGroupsListActions = () => (
        <TopToolbar>
            <CreateButton label = 'Create' />
        </TopToolbar>
)

const DealershipNameField = ({ source, record }) => {
    const dealershipNames = record[source].map(iri => iri.split('/')[4]);
    return dealershipNames.length === 0
        ? <td>No custom dealerships</td>
        : <td>
            {dealershipNames.map((name, index) => (
                <Chip key={index} label={name} sx={{margin: '4px'}} />
            ))}
        </td>
};


const CountryNameField = ({source, record}) =>{
    const countryNames = record[source].map(iri => iri.split('/')[2]);
    return countryNames.length === 0
    ? <td>No countries</td>
    : <td>
            {countryNames.map((name, index) => (
                <Chip key={index} label={name} sx={{margin: '4px'}} />
            ))}
        </td>
}

export const DealershipGroupsList = () => {

    const dispatch = useDispatch();
    const loadingDealershipGroups = useFetchDealershipGroups();
    const dealershipGroups = useSelector(state => state.dealershipGroup.details || [] );
    const totalItems = useSelector(state=>state.dealershipGroup.totalItems || 0);
    const selectedBrand = useSelector(state => state.brand.brand);
    const page = useSelector(state => state.page.page);
    const dataProvider = useDataProvider();

    const [searchDealershipGroupsTitle, setSearchDealershipGroupsTitle] = useState('');
    const [searchDealershipGroupsCountry, setSearchDealershipGroupsCountry] = useState('');
    const [searchDealershipGroupsKvps, setSearchDealershipGroupsKvps] = useState('');

    const [searchDealershipGroupsResults, setSearchDealershipGroupsResults] = useState([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        searchDealershipGroups('');
    }, [dataProvider]);

    const debouncedSearchDealershipGroups = useCallback(
        debounce((query, isCountry, isTitle, isKvps) => {
            searchDealershipGroups(query, isCountry, isTitle, isKvps);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealershipGroups.cancel();
        };
    }, [debouncedSearchDealershipGroups]);

    const searchDealershipGroups = async (query, isCountry, isTitle, isKvps) => {
        if(query.length > 2){
            try {
                setIsLoading(true)
                const response = await dataProvider.getList('dealershipGroups',{
                    filter:{
                        ...(isCountry && {'countries.iso3': query.toUpperCase()}),
                        ...(isTitle && {title: query}),
                        ...(isKvps && {'dealerships.kvps': query.toUpperCase()})
                        },
                    pagination:{page:1},
                });
                setSearchDealershipGroupsResults(response.data);
                dispatch(setDealershipGroupTotalItems(response.total));
                dispatch(setDealershipGroupDetails(response.data));
                setShowSearchResults(true);
                setIsLoading(false)
            }catch(error){
                console.error('Error fetching dealership Groups', dealershipGroups);
                setIsLoading(false)
            }
        }else {
            setShowSearchResults(false);
            setSearchDealershipGroupsResults([]);
        }
    };

    const handleSearchDealershipGroupsCountryChange = (event) => {
        const query = event.target.value
        setSearchDealershipGroupsCountry(query);
        setSearchDealershipGroupsTitle('');
        setSearchDealershipGroupsKvps('');
        debouncedSearchDealershipGroups(query,true,false,false)
    };

    const handleSearchDealershipGroupsTitleChange = (event) => {
        const query = event.target.value
        setSearchDealershipGroupsCountry('');
        setSearchDealershipGroupsTitle(query);
        setSearchDealershipGroupsKvps('');
        debouncedSearchDealershipGroups(query,false,true,false)
    };

    const handleSearchDealershipGroupsKvpsChange = (event) => {
        const query = event.target.value
        setSearchDealershipGroupsCountry('');
        setSearchDealershipGroupsTitle('');
        setSearchDealershipGroupsKvps(query);
        debouncedSearchDealershipGroups(query,false,false,true)
    };

    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage))
    };

    useEffect(() => {
        if(selectedBrand){
            dispatch(setPage(1));
        }
    }, [selectedBrand, dispatch]);

    if (loadingDealershipGroups) return <Loader/>;
 
    const dealershipGroupsToDisplay = showSearchResults ? searchDealershipGroupsResults : dealershipGroups;

    return (
        <div className='customGrid_dealership'>
            <div className='search_container'>
                <input
                    className='countries_searchbar'
                    type='text'
                    placeholder='Search by KVPS'
                    value = {searchDealershipGroupsKvps}
                    onChange={handleSearchDealershipGroupsKvpsChange}
                />  
                <SearchIcon className='search_icon'/>
             </div>            
             <div className='search_container'>
                    <input
                        className='countries_searchbar'
                        type='text'
                        placeholder='Search by Country iso3'
                        value = {searchDealershipGroupsCountry}
                        onChange={handleSearchDealershipGroupsCountryChange}
                    /> 
                    <SearchIcon className='search_icon'/>
                </div>            
                <div className='search_container'>
                    <input
                        className='countries_searchbar'
                        type='text'
                        placeholder='Search by Title'
                        value = {searchDealershipGroupsTitle}
                        onChange={handleSearchDealershipGroupsTitleChange}
                    />
                    <SearchIcon className='search_icon'/>
                </div>
            <Title title = 'Dealership Groups'/>
            <DealershipGroupsListActions/>
            {isLoading ? 
            <Loader/> :
            dealershipGroupsToDisplay.length === 0 ? 
            <NotFound resource='dealershipGroups' /> :
            <table className='dealerships_table'>
                <thead>
                    <tr className='dealershipsHead'>
                        <th>Brand</th>
                        <th>Title</th>
                        <th>Countries</th>
                        <th>Custom dealerships</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {dealershipGroupsToDisplay.map(dealershipGroup => {
                        const brandCode= dealershipGroup.brand.split('/').pop();
                        const brand = BRAND_DETAILS.find(b=>b.code===brandCode);
                        const brandName = brand ? brand.name : dealershipGroup.brand;

                        return (
                            <tr key={dealershipGroup.id} className='dealershipsRow'>
                                <td>{brandName}</td>
                                <td>{dealershipGroup.title}</td>
                                <CountryNameField source = 'countries' record = {dealershipGroup} />
                                <DealershipNameField source = 'dealerships' record = {dealershipGroup} />
                                <td className='crud_buttons'>
                                    <EditButton className='edit-button' basepath='/dealershipGroups' record={dealershipGroup} />
                                    <ShowButton className='show-button' basepath='/dealershipGroups' record={dealershipGroup} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>}
            {!showSearchResults && <Pagination total={totalItems} current={page} onChange={handlePageChange} />}
        </div>
    )
}

const extractDealershipGroupData = (data) => {
    return {
        '@id':data['@id'] || '',
        '@type':data['@type'],
        uuid: data.uuid,
        title: data.title,
        brand: data.brand,
        countries: data.countries || [],
        dealerships: data.dealerships || [],
        users: data.users || [],
    };
};

export const DealershipGroupsCreate = props => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const dealerships = useSelector(state => state.dealershipGroup.dealerships|| {});
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');
    const [searchUserResults, setSearchUserResults] = useState('');
    const [searchCountryResults, setSearchCountryResults] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedDealerships, setSelectedDealerships] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [searchUserQuery, setSearchUserQuery] = useState('');
    const [searchCountryQuery, setSearchCountryQuery] = useState('');
    const [searchDealershipResults, setSearchDealershipResults] = useState('');
    const [searchDealershipQuery, setSearchDealershipQuery] = useState('');
    const [isSearchingDealership, setIsSearchingDealership] = useState(false);
    const [isSearchingCountry, setIsSearchingCountry] = useState(false);
    const [isSearchingUser, setIsSearchingUser] = useState(false);

    const dealershipGroups = useSelector(state => state.dealershipGroup.details || []);

    const searchDealership = async (query) => {
        if (query.length > 2){
            try {
                setIsSearchingDealership(true)
                const response = await dataProvider.getList('dealerships',{
                    filter:{kvps: query, brand:selectedBrand},
                    pagination:{page:1, perPage: 30},
                    sort: {field: 'kvps', order: 'ASC'}
                });

                setSearchDealershipResults(response.data)
                setIsSearchingDealership(false)

            }catch(error){
                console.error('Error fetching dealerships', dealerships);
                setIsSearchingDealership(false)
            }
        }else {
            setSearchDealershipResults('');
        }
    }

    const handleSearchDealershipChange = (event) => {
        const query = event.target.value
        setSearchDealershipQuery(query);
        debouncedSearchDealership(query)
    };

    useEffect(() => {
        searchDealership('');
    }, [dataProvider]);

    const debouncedSearchDealership = useCallback(
        debounce((query) => {
            searchDealership(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealership.cancel();
        };
    }, [debouncedSearchDealership]);

    useEffect(() => {
        searchUser('');
    }, [dataProvider]);

    const debouncedSearchUser = useCallback(
        debounce((query) => {
            searchUser(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchUser.cancel();
        };
    }, [debouncedSearchUser]);

    useEffect(()=>{
        searchCountry('');
    },[dataProvider])

    const debouncedSearchCountry = useCallback(
        debounce((query) => {
            searchCountry(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchCountry.cancel();
        };
    }, [debouncedSearchCountry]);

    const searchUser = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingUser(true)
                const response = await dataProvider.getList('users', {
                    filter: { email: query },
                    pagination: { page: 1, perPage: 30 },
                    sort: { field: 'email', order: 'ASC' }
                });

                setSearchUserResults(response.data)

                setIsSearchingUser(false)
            } catch (error) {
                console.error('Error fetching users:', error);
                setIsSearchingUser(false)
            }
        } else {
            setSearchUserResults('');
        }
    };

    const CustomToolbar = () => {
        const { save } = useSaveContext();

        const handleSaveClick = (event) => {
            event.preventDefault(); // Prevent default form submission
            save(); // Use the custom save function
        };

        return (
            <Toolbar>
                <button style={{display:'none'}} className='save_button' onClick={handleSaveClick} type="button">
                    <SaveIcon fontSize='small'/>
                    <div className='save_text'>SAVE</div>
                </button>
            </Toolbar>
        );
    };

    const renderUsers = () => {
        return (
            <div>
                {selectedUsers.map((user, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                        {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleUserRemove(user)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleUserRemove = (userToRemove) => {
        setSelectedUsers(prevUsers =>
            prevUsers.filter(user => user.id !== userToRemove.id)
        );
    };

    const handleDealershipRemove = (dealershipToRemove) => {
        setSelectedDealerships(prevDealerships =>
            prevDealerships.filter(dealership => dealership.id !== dealershipToRemove.id)
        );
    };

    const handleSearchUserChange = (event) => {
        const query = event.target.value
        setSearchUserQuery(query);
        debouncedSearchUser(query)
    };

    const handleCountryRemove = (countryToRemove) => {
        setSelectedCountries(prevCountries =>
            prevCountries.filter(country => country.id !== countryToRemove.id)
        );
    };

    const renderCountries = () => {
        return (
            <div>
                {selectedCountries.map((country, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {country.name}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleCountryRemove(country)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleUserSelect = (user) => {
        setSelectedUsers(prev => {
            const isDuplicate = prev.some(existingUser => existingUser.id === user.id);
            return isDuplicate ? prev : [...prev, user];
        });
        setSearchUserResults('');
    };

    const handleCountrySelect = (country) => {
        setSelectedCountries(prev => {
            const isDuplicate = prev.some(existingCountry => existingCountry.id === country.id);
            return isDuplicate ? prev : [...prev, country];
        });
        setSearchCountryResults('');
    };

    const handleSearchCountryChange = (event) => {
        const query = event.target.value
        setSearchCountryQuery(query);
        debouncedSearchCountry(query)

    };

    const searchCountry = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingCountry(true)
                const response = await dataProvider.getList('countries', {
                    filter: { name: query },
                    pagination: { page: 1, perPage: 30 },
                    sort: { field: 'iso3', order: 'ASC' }
                });

                setSearchCountryResults(response.data)
                setIsSearchingCountry(false)
            } catch (error) {
                console.error('Error fetching countries:', error);
                setIsSearchingCountry(false)
            }
        } else {
            setSearchCountryResults('');
        }
    };

    const handleDealershipSelect = (dealership) => {
        setSelectedDealerships(prev => {
            const isDuplicate = prev.some(existingDealership => existingDealership.id === dealership.id);
            return isDuplicate ? prev : [...prev, dealership];
        });
        setSearchDealershipResults('');
    };

    const renderDealerships = () => {
        return (
            <div>
                {selectedDealerships.map((dealership, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {dealership.title}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleDealershipRemove(dealership)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Create {...props} title='Create Dealership Group'>
                <SimpleForm toolbar = {<CustomToolbar/>} defaultValues={()=>({brand: '/brands/'+selectedBrand })}>
                    <TextInput fullWidth source='title' validate={[required()]}/>
                    {/* search countries */}
                    <h3 className='countries_title'>Countries:</h3>
                    <div className='countries' style={{marginBottom:'20px'}}>
                        {renderCountries()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search for country'
                                value = {searchCountryQuery}
                                onChange={handleSearchCountryChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        {Array.isArray(searchCountryResults) && !isSearchingCountry &&
                            (searchCountryResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchCountryResults.map(country => (
                            <div className = 'search-result-options' key={country.id} onClick={() => handleCountrySelect(country)}>
                                {country.name}
                            </div>
                        )))}
                        {isSearchingCountry && <div>Searching...</div>}
                    </div>

                    <h3 className='countries_title'>Custom dealerships:</h3>
                    <div className='countries' style={{marginBottom:'20px'}}>
                        {renderDealerships()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search for dealership by KVPS'
                                value = {searchDealershipQuery}
                                onChange={handleSearchDealershipChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        {Array.isArray(searchDealershipResults) && !isSearchingDealership &&
                            (searchDealershipResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchDealershipResults.map(dealership => (
                            <div className='search-result-options' key={dealership.id} onClick={() => handleDealershipSelect(dealership)}>
                                {dealership.title}
                            </div>
                        )))}
                        {isSearchingDealership && <div>Searching...</div>}
                    </div>

                    {/* search users */}
                    <h3 className='countries_title'>Users:</h3>
                    <div className='countries'>
                        {renderUsers()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search user by email'
                                value = {searchUserQuery}
                                onChange={handleSearchUserChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        {Array.isArray(searchUserResults) && !isSearchingUser &&
                            (searchUserResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchUserResults.map(user => (
                            <div className='search-result-options' key={user.id} onClick={() => handleUserSelect(user)}>
                                {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                            </div>
                        )))}
                        {isSearchingUser && <div>Searching...</div>}
                    </div>
                    <FormDataConsumer>
                        {({ formData, }) => {
                            const handleCustomSave = async () => {
                                formData.users = selectedUsers.map(user=>user['@id'])
                                formData.countries = selectedCountries.map(country => country['@id']);
                                formData.dealerships = selectedDealerships.map(dealership => dealership['@id']);
                                try {
                                    if(!formData.title) { throw new Error('The title field is mandatory and must be filled out.') }
                                    const response = await dataProvider.create('dealershipGroups', { data: formData})
                                    const extractedData = extractDealershipGroupData(response.data)
                                    const updatedDealershipGroups = [extractedData, ...dealershipGroups]
                                    dispatch(setDealershipGroupDetails(updatedDealershipGroups));

                                    notify('Dealership group created successfully', 'info');
                                    redirect('/dealershipGroups');
                                } catch (error) {
                                    console.error('Error creating dealership group:', error);
                                    notify(`Error creating dealership group: ${error.message}`, 'warning');
                                }
                            };

                            return (
                                <button style={{position:'relative', top:'70px', zIndex:'99'}}className='save_button' onClick={e => handleCustomSave(e)} type="button">
                                    <SaveIcon fontSize='small'/>
                                    <div className='save_text'>SAVE</div>
                                </button>
                            );
                        }}
                    </FormDataConsumer>

                </SimpleForm>
        </Create>
   )
}

export const DealershipGroupsEdit = props => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const dealershipGroups = useSelector(state => state.dealershipGroup.details || []);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');

    const dealerships = useSelector(state => state.dealershipGroup.dealerships|| []);
    const [searchUserResults, setSearchUserResults] = useState('');
    const [searchDealershipResults, setSearchDealershipResults] = useState('');
    const [searchCountryResults, setSearchCountryResults] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedDealerships, setSelectedDealerships] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [searchUserQuery, setSearchUserQuery] = useState('');
    const [searchDealershipQuery, setSearchDealershipQuery] = useState('');
    const [searchCountryQuery, setSearchCountryQuery] = useState('');
    const [isSearchingCountry, setIsSearchingCountry] = useState(false);
    const [isSearchingUser, setIsSearchingUser] = useState(false);
    const [isSearchingDealership, setIsSearchingDealership] = useState(false);
    const {id} = useParams();
    const dealershipGroup = dealershipGroups ? dealershipGroups.find(group => group.uuid === id) : null;
    const [isFetchingAdditionalDetails, setIsFetchingAdditionalDetails] = useState(false);
    const brandCode= dealershipGroup.brand.split('/').pop();

    useEffect(() => {
        const fetchAdditionalDetails = async () => {
            setIsFetchingAdditionalDetails(true);
            const fetchedUsers = await Promise.all(dealershipGroup.users.map(userId => dataProvider.getOne('users', { id: userId.split('/').pop().trim() })));
            setSelectedUsers(fetchedUsers.map(response => response.data));
            const fetchedCountries = await Promise.all(dealershipGroup.countries.map(countryId => dataProvider.getOne('countries', { id: countryId.split('/').pop().trim() })));
            setSelectedCountries(fetchedCountries.map(response => response.data));
            const fetchedDealerships = await Promise.all(dealershipGroup.dealerships.map(dealershipId => dataProvider.getOne('dealerships', {id: dealershipId.split('/').pop(), meta:{brand:selectedBrand}})));
            setSelectedDealerships(fetchedDealerships.map(response => response.data));
            setIsFetchingAdditionalDetails(false);
        };
        fetchAdditionalDetails();
    }, [dataProvider]);

    useEffect(() => {
        searchUser('');
    }, [dataProvider]);

    useEffect(() => {
        searchDealership('');
    }, [dataProvider]);

    useEffect(()=>{
        searchCountry('');
    },[dataProvider]);

    const debouncedSearchDealership = useCallback(
        debounce((query) => {
            searchDealership(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealership.cancel();
        };
    }, [debouncedSearchDealership]);

    const debouncedSearchCountry = useCallback(
        debounce((query) => {
            searchCountry(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchCountry.cancel();
        };
    }, [debouncedSearchCountry]);

    const debouncedSearchUser = useCallback(
        debounce((query) => {
            searchUser(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchUser.cancel();
        };
    }, [debouncedSearchUser]);

    const searchUser = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingUser(true)
                const response = await dataProvider.getList('users', {
                    filter: { email: query },
                    pagination: { page: 1, perPage: 30 },
                    sort: { field: 'email', order: 'ASC' }
                });

                setSearchUserResults(response.data)
            
                setIsSearchingUser(false)
            } catch (error) {
                console.error('Error fetching users:', error);
                setIsSearchingUser(false)

            }
        } else {
            setSearchUserResults('');
        }
    };

    const searchDealership = async (query) => {
        if (query.length > 2){
            try {
                setIsSearchingDealership(true)
                const response = await dataProvider.getList('dealerships',{
                    filter:{kvps: query, brand:selectedBrand},
                    pagination:{page:1, perPage: 30},
                    sort: {field: 'kvps', order: 'ASC'}
                });

                setSearchDealershipResults(response.data)
                setIsSearchingDealership(false)
            }catch(error){
                console.error('Error fetching dealerships', dealerships);
                setIsSearchingDealership(false)
            }
        }else {
            setSearchDealershipResults('');
        }
    }

    const CustomDeleteButton = ({record, brandCode}) => {
        const [open, setOpen] = useState(false);
        const dataProvider = useDataProvider();
        const notify = useNotify();
        const refresh = useRefresh();
        const redirect = useRedirect();
    
        const handleDelete = async () => {
            setOpen(false);
            try {
                await dataProvider.delete('dealershipGroups', { id: record.id, brandCode });
                refresh();
                notify('Resource deleted successfully', 'info');
                redirect('/dealershipGroups');
            } catch (error) {
                notify(`Error deleting resource: ${error.message}`, 'warning');
            }
        };
        return (
            <>
                <Button label='Delete' onClick={() => setOpen(true)} style = {{marginLeft:'10px'}}>
                    <DeleteIcon />
                </Button>
    
                <Confirm
                    isOpen={open}
                    loading={false}
                    title='Are you sure you want to delete this group?'
                    content='Only the person that has created a dealership group can delete it. Even then, the dealership group must first be emptied out.'
                    onConfirm={handleDelete}
                    onClose={() => setOpen(false)}
                />
            </>
        );
    };

    const CustomToolbar = () => {
        const { save } = useSaveContext();

        const handleSaveClick = (event) => {
            event.preventDefault();
            save();
        };

        return (
            <Toolbar style={{display:'flex', justifyContent:'flex-end'}}>
                <button style={{display:'none'}} className='save_button' onClick={handleSaveClick} type="button">
                    <SaveIcon fontSize='small'/>
                    <div className='save_text'>SAVE</div>
                </button>
                <CustomDeleteButton  record={dealershipGroup} brandCode={brandCode} />
            </Toolbar>
        );
    };

    const renderUsers = () =>
        selectedUsers.length === 0
        ? <div>No users</div>
        : <div>
                {selectedUsers.map((user, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                        {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleUserRemove(user)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>

    const renderDealerships = () => 
        selectedDealerships.length ===0
            ?<div>No dealers</div>
            :<div>
                {selectedDealerships.map((dealership, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {dealership.title}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleDealershipRemove(dealership)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>

    const handleDealershipRemove = (dealershipToRemove) => {
        setSelectedDealerships(prevDealerships =>
            prevDealerships.filter(dealership => dealership.id !== dealershipToRemove.id)
        );
    };

    const handleUserRemove = (userToRemove) => {
        setSelectedUsers(prevUsers =>
            prevUsers.filter(user => user.id !== userToRemove.id)
        );
    };

    const handleSearchUserChange = (event) => {
        const query = event.target.value
        setSearchUserQuery(query);
        debouncedSearchUser(query)
    };

    const handleSearchDealershipChange = (event) => {
        const query = event.target.value
        setSearchDealershipQuery(query);
        debouncedSearchDealership(query)
    };

    const handleCountryRemove = (countryToRemove) => {
        setSelectedCountries(prevCountries =>
            prevCountries.filter(country => country.id !== countryToRemove.id)
        );
    };

    const renderCountries = () => 
        selectedCountries.length ===0
        ? <div>No countries</div>
        : <div>
                {selectedCountries.map((country, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                            {country.name}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleCountryRemove(country)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>

    const handleUserSelect = (user) => {
        setSelectedUsers(prev => {
            const isDuplicate = prev.some(existingUser => existingUser.id === user.id);
            return isDuplicate ? prev : [...prev, user];
        });
        setSearchUserResults('');
    };

    const handleDealershipSelect = (dealership) => {
        setSelectedDealerships(prev => {
            const isDuplicate = prev.some(existingDealership => existingDealership.id === dealership.id);
            return isDuplicate ? prev : [...prev, dealership];
        });
        setSearchDealershipResults('');
    };

    const handleCountrySelect = (country) => {
        setSelectedCountries(prev => {
            const isDuplicate = prev.some(existingCountry => existingCountry.id === country.id);
            return isDuplicate ? prev : [...prev, country];
        });
        setSearchCountryResults('');
    };

    const handleSearchCountryChange = (event) => {
        const query = event.target.value
        setSearchCountryQuery(query);
        debouncedSearchCountry(query)
    };

    const searchCountry = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingCountry(true)
                const response = await dataProvider.getList('countries', {
                    filter: { name: query },
                    pagination: { page: 1, perPage: 30 },
                    sort: { field: 'iso3', order: 'ASC' }
                });

                setSearchCountryResults(response.data)
                setIsSearchingCountry(false)
            } catch (error) {
                console.error('Error fetching countries:', error);
                setIsSearchingCountry(false)
            }
        } else {
            setSearchCountryResults('');
        }
    };

    if (isFetchingAdditionalDetails) return <Loader/>

    return (
        <Edit {...props} title={dealershipGroup.title}>
                <SimpleForm toolbar = {<CustomToolbar/>}>
                    <TextInput fullWidth source='title'/>
                    {/* search countries */}
                    <h3 className='countries_title'>Countries:</h3>
                    <div className='countries'  style={{marginBottom:'20px'}}>
                        {renderCountries()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search for country'
                                value = {searchCountryQuery}
                                onChange={handleSearchCountryChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        {Array.isArray(searchCountryResults) && !isSearchingCountry &&
                            (searchCountryResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchCountryResults.map(country => (
                            <div className = 'search-result-options' key={country.id} onClick={() => handleCountrySelect(country)}>
                                {country.name}
                            </div>
                        )))}
                        {isSearchingCountry && <div>Searching...</div>}
                    </div>
                    <h3 className='countries_title'>Custom dealerships:</h3>
                    <div className='countries' style={{marginBottom:'20px'}}>
                        {renderDealerships()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search for dealership by KVPS'
                                value = {searchDealershipQuery}
                                onChange={handleSearchDealershipChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        {Array.isArray(searchDealershipResults) && !isSearchingDealership &&
                            (searchDealershipResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchDealershipResults.map(dealership => (
                            <div className='search-result-options' key={dealership.id} onClick={() => handleDealershipSelect(dealership)}>
                                {dealership.title}
                            </div>
                        )))}
                        {isSearchingDealership && <div>Searching...</div>}
                    </div>
                    <h3 className='countries_title'>Users:</h3>
                    <div className='countries' style={{marginBottom:'20px'}}>
                        {renderUsers()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search user by email'
                                value = {searchUserQuery}
                                onChange={handleSearchUserChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>
                        {Array.isArray(searchUserResults) && !isSearchingUser &&
                            (searchUserResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchUserResults.map(user => (
                            <div className='search-result-options' key={user.id} onClick={() => handleUserSelect(user)}>
                                {user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}
                            </div>
                        )))}
                        {isSearchingUser && <div>Searching...</div>}
                    </div>
                    <FormDataConsumer>
                        {({ formData, }) => {
                            const handleCustomSave = async () => {
                                let dataToSet = {};
                                const previousData = JSON.parse(JSON.stringify(formData));
                                dataToSet.title = formData.title;
                                dataToSet.users = selectedUsers.map(user=>user['@id']);
                                dataToSet.countries = selectedCountries.map(country => country['@id']);
                                dataToSet.dealerships = selectedDealerships.map(dealership => dealership['@id']);

                                try {
                                    await dataProvider.update('dealershipGroups', { id:dealershipGroup['@id'], data: dataToSet, previousData: previousData})

                                    const updatedDealershipGroups = dealershipGroups.map((dealershipGroup) => {
                                        if(dealershipGroup.id === previousData.id){
                                            return {...dealershipGroup, ...dataToSet};
                                        }
                                        return dealershipGroup;
                                    })

                                    dispatch(setDealershipGroupDetails(updatedDealershipGroups));

                                    notify('Dealership group updated successfully', 'info');
                                    redirect(`/dealershipGroups/${dealershipGroup.id}/show`);
                                } catch (error) {
                                    console.error('Error updating dealership group:', error);
                                    notify(`Error updating dealership group: ${error.message}`, 'warning');
                                }
                            };

                            return (
                                <button style={{position:'relative', top:'70px', zIndex:'99'}} className='save_button' onClick={e => handleCustomSave(e)} type="button">
                                    <SaveIcon fontSize='small'/>
                                    <div className='save_text'>SAVE</div>
                                </button>
                            );
                        }}
                    </FormDataConsumer>

                </SimpleForm>
        </Edit>
   )
}

const BrandNameField = ({ source }) => {
    const record = useRecordContext();

    if (!record) return null;
    const brandCode = record[source].split('/').pop();
    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
    return <span>{brand ? brand.name : 'Unknown'}</span>;
};

const DealershipsTitles = ({ dealershipsMap }) => (
    dealershipsMap.length > 0 ?
        <div>
            {dealershipsMap.map((dealership, index) => (
                <div key={index}>{dealership.title}</div>
            ))}
        </div>
         :
         <div>No custom dealerships</div>
    );

const CountriesTitles = ({ countriesMap }) => (
        countriesMap.length > 0 ?
            <div>
                {countriesMap.map((country, index) => (
                    <div key={index}>{country.name}</div>
                ))}
            </div>
         :
         <div>No countries</div>
    );

const UsersTitles = ({ usersMap }) =>( 
    usersMap.length > 0 ?
            <div>
                {usersMap.map((user, index) => (
                    <div key={index}>{user.familyName || user.givenName ? user.familyName + ' ' + user.givenName + ' <' + user.email + '>' : user.email}</div>
                ))}
            </div>
        :
        <div>No users</div>
    );


export const DealershipGroupsShow = () => {
    const dealershipGroups = useSelector(state => state.dealershipGroup.details || []);
    const [isFetchingAdditionalDetails, setIsFetchingAdditionalDetails] = useState(false);
    const [showUsers, setShowUsers] = useState([]);
    const [showDealerships, setShowDealerships] = useState([]);
    const [showCountries, setShowCountries] = useState([]);
    const {id} = useParams();
    const dealershipGroup = dealershipGroups ? dealershipGroups.find(d => d.uuid === id) : null;
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchAdditionalDetails = async () => {
            setIsFetchingAdditionalDetails(true);
            const fetchedUsers = await Promise.all(dealershipGroup.users.map(userId => dataProvider.getOne('users', { id: userId.split('/').pop().trim() })));
            setShowUsers(fetchedUsers.map(response => response.data));
            const fetchedCountries = await Promise.all(dealershipGroup.countries.map(countryId => dataProvider.getOne('countries', { id: countryId.split('/').pop().trim() })));
            setShowCountries(fetchedCountries.map(response => response.data));
            const fetchedDealerships = await Promise.all(dealershipGroup.dealerships.map(dealershipId => dataProvider.getOne('dealerships', {id: dealershipId.split('/').pop()})));
            setShowDealerships(fetchedDealerships.map(response => response.data));
            setIsFetchingAdditionalDetails(false);
        };
        fetchAdditionalDetails();
    }, [dataProvider]);

    if(isFetchingAdditionalDetails) return <Loader/>;
    if(!dealershipGroup) return <NotFound resource = 'dealership groups' />

    return (
        <Show title={dealershipGroup.title}>
            <SimpleShowLayout>
                <TextField source='title'/>
                <BrandNameField source = 'brand' label='Brand' />
                <span>Countries:</span>
                <CountriesTitles
                    countriesMap={showCountries}
                />
                <span>Custom dealerships:</span>
                <DealershipsTitles
                    dealershipsMap={showDealerships}
                />
                <span>Users:</span>
                <UsersTitles
                    usersMap={showUsers}
                />
            </SimpleShowLayout>
        </Show>
    )
}


export const DealershipGroupsDelete = ({ record }) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const dealershipGroups = useSelector(state => state.dealershipGroup.details || []);
    const refresh = useRefresh();

    const handleDelete = async () => {
        try {
            await dataProvider.delete('dealershipGroups', { id: record.id });
            const updatedDealershipGroups = dealershipGroups.filter(dealershipGroup => dealershipGroup.id !== record.id);
            dispatch(setDealershipGroupDetails(updatedDealershipGroups))
            refresh()
            notify('Dealership group deleted successfully', 'info');
            redirect('/dealershipsGroups');
        } catch (error) {
            notify(`Error deleting dealership group: ${error.message}`, 'warning');
            console.error('Error deleting dealership group:', error);
        }
    };
    return <Button onClick={handleDelete}>Delete</Button>;
};